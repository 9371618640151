// routes.js
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Posts from "./pages/Posts";
import AddPost from './pages/AddPost';
import EditPost from './pages/EditPost';
import Login from './pages/Login';
import Video from './pages/Video';
import Dashboard from './components/Dashboard';

const AppRoutes = ({ isLoggedIn, onLogin }) => {

  //console.log(isLoggedIn)
    return (
        <Routes>
            <Route path="/" element={isLoggedIn ? <div><Dashboard /></div> : <Navigate to="/login" />} />
            <Route path="/posts" element={isLoggedIn ? <Posts /> : <Navigate to="/login" />} />
            <Route path="/add-post" element={isLoggedIn ? <AddPost /> : <Navigate to="/login" />} />
            <Route path="/edit-post/:id" element={isLoggedIn ? <EditPost /> : <Navigate to="/login" />} />
            <Route path="/login" element={<Login onLogin={onLogin}/>} />
            <Route path="/video/:tmdb/:t/:e" element={ <Video /> } />
        </Routes>
    )
}

export default AppRoutes;