import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import axios from 'axios';

const BASE_URL = window.BASE_URL;

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState({
    last5Posts: [],
    totalPosts: 0,
    totalEpisodes: 0,
  });

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}dashboard.php`);
        setDashboardData(response.data);
      } catch (error) {
        console.error('Erro ao buscar dados do dashboard:', error);
      }
    };

    fetchDashboardData();
  }, []);

  return (
    <Box sx={{ padding: '20px', textAlign: 'center' }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Dashboard
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Card >
            <CardContent>
              <Typography variant="h6" component="h2" gutterBottom>
                Últimos 5 Posts Adicionados
              </Typography>
              <ul style={{ listStyleType: 'none', padding: 0 }}>
                {dashboardData?.last5Posts ? dashboardData.last5Posts.map((post) => (
                  <li key={post.post_id}>{post.title}</li>
                )) : "No rows"}
              </ul>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} sx={{ marginBottom: 5 }}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text" gutterBottom>
                Total de Posts:
              </Typography>
              <Typography variant="h5" component="div">
                {dashboardData.totalPosts}
              </Typography>

            </CardContent>
          </Card>


          <Card sx={{ minWidth: 275, marginTop:3 }}>
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text" gutterBottom>
                Total de Episódios:
              </Typography>
              <Typography variant="h5" component="div">
                {dashboardData.totalEpisodes}
              </Typography>

            </CardContent>
          </Card>
        </Grid>
      </Grid>

    </Box>
  );
};

export default Dashboard;
