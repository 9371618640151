import React, { useEffect, useState, useRef } from "react";
import { useParams } from 'react-router-dom';
import axios from "axios";
import {
    Player,
    ControlBar,
    ReplayControl,
    ForwardControl,
    CurrentTimeDisplay,
    TimeDivider,
    VolumeMenuButton,
    BigPlayButton
} from 'video-react';
import 'video-react/dist/video-react.css';

const BASE_URL = window.BASE_URL;

const Video = () => {
    const [videoSources, setVideoSources] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedQuality, setSelectedQuality] = useState(null);
    const [currentTime, setCurrentTime] = useState(0);
    const { tmdb, t, e } = useParams();
    const playerRef = useRef();

    useEffect(() => {
        const fetchVideoSources = async () => {
            try {
                document.body.classList.add("loading");
                const response = await axios.get(`${BASE_URL}posts/get-video.php`, {
                    params: {
                        episode_number: e,
                        season_number: t,
                        tmdb: tmdb,
                    },
                });

                if (response.data.success) {
                    setVideoSources(response.data.videoUrl);
                    // Definir a primeira qualidade como padrão
                    setSelectedQuality(response.data.videoUrl[0].label);
                } else {
                    console.error("Erro ao obter as fontes de vídeo:", response.data.message);
                }

                setLoading(false);

            } catch (error) {
                console.error("Erro na requisição ao servidor:", error);
                setLoading(false);
            }
        };

        fetchVideoSources();
    }, [tmdb, t, e]);

    const handleQualityChange = (event) => {
        // Salvar a posição atual do vídeo
        const currentTime = playerRef.current.getState().player.currentTime;

        // Pausar o vídeo
        playerRef.current.pause();

        // Alterar a fonte do vídeo com base na qualidade selecionada
        const quality = event.target.value;
        const selectedSource = videoSources.find(source => source.label === quality);
        if (selectedSource) {
            // Recarregar o vídeo com a nova fonte
            playerRef.current.load(selectedSource.file);

            // Esperar um pouco antes de buscar o currentTime, pois o load pode não ser imediato
            setTimeout(() => {
                // Definir a posição atual para onde o vídeo estava antes da mudança
                playerRef.current.seek(currentTime);

                // Retomar a reprodução do vídeo
                playerRef.current.play();
            }, 100); // Ajuste o tempo conforme necessário
            setSelectedQuality(quality);
        }
    };


    return (
        <>
            {loading && <div className="videoLoad"><img src="/loader.svg" /></div>}
            {!loading && (
                <>
                    <Player
                        ref={playerRef}
                        autoPlay
                        fluid={false}
                        width={720}
                        height={420}
                        poster="/path/to/poster.jpg"
                        src={videoSources.find(source => source.label === selectedQuality)?.file}
                    >
                        <BigPlayButton position="center" />
                        <ControlBar>
                            <ReplayControl seconds={10} order={1.1} />
                            <ForwardControl seconds={30} order={1.2} />
                            <CurrentTimeDisplay order={4.1} />
                            <TimeDivider order={4.2} />
                            <VolumeMenuButton />
                            {/* Adicionando o seletor de qualidade ao lado do botão de tela cheia */}
                            <select order={7}
                                style={{
                                    background: "rgba(43, 51, 63, 0.7)",
                                    color: "#ccc",
                                    outline: "none",
                                    border: "0",
                                    marginRight: "10px" // Espaçamento para separar os elementos
                                }} 
                                value={selectedQuality} 
                                onChange={handleQualityChange}
                            >
                                {videoSources.map((source, index) => (
                                    <option key={index} value={source.label}>{source.label}</option>
                                ))}
                            </select>
                        </ControlBar>
                    </Player>
                </>
            )}
        </>
    );
};

export default Video;
