import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import Button from '@mui/material/Button';
import { useQueryClient } from 'react-query';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const BASE_URL = window.BASE_URL;

const Posts = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10, // posts per page
    pageCount: 0, // total de paginas
  });

  const [data, setData] = useState({
    posts: [],
    page: 0,
    totalPosts: 0,
    totalPages: 0,
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const fetchPosts = async (page) => {
    try {
      const response = await axios.get(`${BASE_URL}posts/posts.php?page=${page}`);

      if (response.data?.posts)
        return response.data;
      else {
        return {
          posts: [],
          page: 0,
          totalPosts: 0,
          totalPages: 0,
        };
      }
    } catch (error) {
      console.error('Erro ao buscar posts:', error);
      return {
        posts: [],
        page: 0,
        totalPosts: 0,
        totalPages: 0,
      };
    }
  };

  const fetchData = async (page) => {
    const initialData = await fetchPosts(page);

    setData(initialData);
    setPaginationModel((prevModel) => ({
      ...prevModel,
      pageCount: initialData.totalPages,
    }));
  };

  const handlePageChange = async (params) => {
    const newPage = params.page + 1; // MUI DataGrid page is 0-based, adjust to 1-based
    await fetchData(newPage);

    setPaginationModel((prevModel) => ({
      ...prevModel,
      page: newPage - 1, // Adjust the page here
    }));
  };

  const handleEditPost = (postId) => {
    navigate(`/edit-post/${postId}`);
  };

  const handleCreatePost = () => {
    navigate('/add-post');
  };
  const handleDeletePost = async (postId) => {
    try {
      await axios.delete(`${BASE_URL}posts/delete-post.php?id=${postId}`);
      queryClient.invalidateQueries('posts');
  
      // Exibe a mensagem de sucesso
      setSnackbarMessage('Post excluído com sucesso');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
  
      // Atualiza localmente os dados removendo o post
      const updatedPosts = data.posts.filter((post) => post.post_id !== postId);
      setData((prevData) => ({
        ...prevData,
        posts: updatedPosts,
        totalPosts: prevData.totalPosts - 1,
      }));
    } catch (error) {
      console.error('Erro ao excluir post:', error);
  
      // Exibe a mensagem de erro
      setSnackbarMessage('Erro ao excluir post');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    fetchData(paginationModel.page + 1); // Inicialmente, carrega os dados para a primeira página
  }, []); // Empty dependency array para executar apenas uma vez no montar

  const columns = [
    { field: 'post_id', headerName: 'ID', width: 70 },
    { field: 'tmdb', headerName: 'TMDB', width: 70 },
    { field: 'title', headerName: 'Title', flex: 1 },
    /* { field: 'tmdb', headerName: 'TMDB', flex: 1 }, */
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      width: 150,
      renderCell: (params) => (
        <>
          <Button onClick={() => handleEditPost(params.id)} color="primary">
            Editar
          </Button>
          <Button onClick={() => handleDeletePost(params.id)} color="secondary">
            Deletar
          </Button>
        </>
      ),
    },
  ];

  return (
    <div style={{ height: 500, width: '100%' }}>
      <h2 style={{ textAlign: 'left' }}>Lista de posts</h2>
      <DataGrid
        getRowId={(P) => P.post_id}
        rows={data.posts}
        columns={columns}
        paginationModel={paginationModel}
        paginationMode="server"
        pageSizeOptions={[10]}
        rowCount={data.totalPosts}
        onPaginationModelChange={handlePageChange}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={snackbarSeverity}
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Posts;
