import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const BASE_URL = window.BASE_URL;

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  // Verifica se já existe um token salvo no localStorage
  useEffect(() => {
    const storedToken = localStorage.getItem('authToken');
    if (storedToken) {
      // Se houver um token, redirecione para a página de dashboard
      navigate('/');
    }
  }, [navigate]);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    setErrorMessage('');
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrorMessage('');
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault(); // Evita que a página seja recarregada

    try {
      const response = await axios.post(`${BASE_URL}login.php`, { username, password });

      if (response.data.success) {
        // Login bem-sucedido, armazene o token e atualize o estado de login no App
        const { token } = response.data;
        localStorage.setItem('authToken', token);
        onLogin(token);
        navigate('/');
      } else {
        // Exiba a mensagem de erro do servidor
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      // Trate diferentes tipos de erros
      if (error.response) {
        // Resposta do servidor com um status diferente de 2xx
        setErrorMessage(`Erro: ${error.response.status} - ${error.response.data.message}`);
      } else if (error.request) {
        // A solicitação foi feita, mas não houve resposta do servidor
        setErrorMessage('Erro: Sem resposta do servidor');
      } else {
        // Configuração da solicitação com erro
        setErrorMessage('Erro ao configurar a solicitação');
      }
      console.error('Erro ao fazer login:', error);
    }
  };

  return (
    <Box component="form" onSubmit={handleFormSubmit} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h1>Login</h1>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      <TextField
        label="Username"
        variant="outlined"
        fullWidth
        margin="normal"
        value={username}
        onChange={handleUsernameChange}
      />
      <TextField
        label="Password"
        type="password"
        variant="outlined"
        fullWidth
        margin="normal"
        value={password}
        onChange={handlePasswordChange}
      />
      <Button type="submit" variant="contained">
        Login
      </Button>
    </Box>
  );
};

export default Login;
