import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { Alert, Chip, Grid } from "@mui/material";
import { Link } from "react-router-dom";

import dayjs from "dayjs";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import axios from "axios";
const BASE_URL = window.BASE_URL;
const boxSstyle = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  margin: "auto",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#fff",
};

const AddPost = ({ onAddPost, defaultValues, onEditPost }) => {
  const [formData, setFormData] = useState({
    title: "",
    tmdb: "",
    description: "",
    releaseDate: new Date().toISOString(),
    imageUrl: "",
    isMovie: false,
    episodes: {},

    ...defaultValues,
  });

  const [formState, setFormState] = useState({
    multiEpisodesText: "",
    seriesType: "singleEpisode", //multiEpisodes
    multidata: "",
    startNumber: null,
    episodeNumber: "",
    seasonNumber: "",
    episodeLink: "",
    isEditing: false,
    editingEpisode: false,

  });

  const [modalOpen, setModalOpen] = useState(false);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {

    if (e.target.type === "checkbox") {

      if (formData.hasOwnProperty(e.target.name)) {
        setFormData({ ...formData, [e.target.name]: e.target.checked });
      } else {
        setFormState({ ...formState, [e.target.name]: e.target.checked });
      }
    } else {

      if (formData.hasOwnProperty(e.target.name)) {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      } else {
        setFormState({ ...formState, [e.target.name]: e.target.value });
      }

    }
  };

  const fillFormForEdit = () => {
    if (defaultValues) {
      setFormData((prevData) => ({
        ...prevData,
        imageUrl: defaultValues.image_url,
        releaseDate: defaultValues.release_date,
        isMovie: defaultValues.is_movie,
      }));
    }
  };

  useEffect(() => {
    fillFormForEdit();
    if (defaultValues) {
      setFormState({
        ...formState,
        isEditing: true,
      });
    }
  }, [defaultValues]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.title && formData.description) {
      try {
        // Verifica se está editando ou adicionando um novo post
        const apiUrl = formState.isEditing ? `${BASE_URL}posts/edit-post.php` : `${BASE_URL}posts/add-post.php`;

        const response = await axios.post(apiUrl, formData, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.data.success) {
          setSuccessMessage(response.data.message);

          // Se for um novo post, limpa os campos
          if (!formState.isEditing) {
            setFormData({
              title: "",
              tmdb: "",
              description: "",
              releaseDate: new Date().toISOString(),
              imageUrl: "",
              isMovie: false,

              episodes: {},
            });

            setFormState({
              startNumber: null,
              multiEpisodesText: "",
              seriesType: "singleEpisode", //multiEpisodes
              multidata: "",
              episodeNumber: "",
              seasonNumber: "",
              episodeLink: "",
              isEditing: false,
            });
          }
          setErrorMessage("");
        } else {
          setErrorMessage(response.data.message);
          setSuccessMessage("");
        }
      } catch (error) {
        console.error("Erro ao adicionar/editar post:", error);
        setErrorMessage("Erro ao adicionar/editar post. Por favor, tente novamente mais tarde.");
        setSuccessMessage("");
      }
    } else {
      setErrorMessage("Por favor, preencha todos os campos obrigatórios");
      setSuccessMessage("");
    }
  };
  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      releaseDate: date.toISOString(),
    });
  };
  const handleEditEpisode = ({ seasonNumber, episodeNumber }) => {
    // Encontrar o episódio correspondente
    const episodeToEdit = formData.episodes[seasonNumber]?.[episodeNumber];

    if (episodeToEdit) {
      // Abre o modal ao clicar em "Editar"
      setModalOpen(true);


      // Preenche o formulário com os detalhes do episódio
      setFormState((prevData) => ({
        ...prevData,
        editingEpisode: true,
        episodeNumber: episodeNumber.toString(),
        seasonNumber: seasonNumber.toString(),
        episodeLink: episodeToEdit.episode_link,
        seriesType: "singleEpisode", // Marca automaticamente como 'Episódio Único'
      }));
    }
  };

  const handleRemoveEpisode = (seasonNumber, episodeNumber) => {
    setFormData((prevData) => {
      const updatedEpisodes = { ...prevData.episodes };

      // Verifica se a temporada existe
      if (updatedEpisodes[seasonNumber]) {
        // Remove o episódio da temporada
        delete updatedEpisodes[seasonNumber][episodeNumber];

        // Remove a temporada se não houver mais episódios nela
        if (Object.keys(updatedEpisodes[seasonNumber]).length === 0) {
          delete updatedEpisodes[seasonNumber];
        }

        return {
          ...prevData,
          episodes: updatedEpisodes,
        };
      }

      // Retorna os dados sem alterações se a temporada não existir
      return prevData;
    });
  };





  const handleAddEpisode = () => {
    const episodeNumber = parseInt(formState.episodeNumber);
    const seasonNumber = formState.seasonNumber * 1;

    if (!isNaN(episodeNumber) && episodeNumber >= 1 && formState.episodeLink.trim() !== "") {
      setFormState((prevData) => ({
        ...prevData,
        episodeNumber: "",
        seasonNumber: "",
        episodeLink: "",
      }));

      setFormData((prevData) => {
        const existingSeason = prevData.episodes[seasonNumber] || {};
        const updatedSeason = {
          ...existingSeason,
          [episodeNumber]: {
            episode_id: null,
            episode_number: episodeNumber,
            season_number: seasonNumber,
            episode_link: formState.episodeLink.trim(),
          },
        };

        return {
          ...prevData,
          episodes: {
            ...prevData.episodes,
            [seasonNumber]: updatedSeason,
          },
        };
      });

      setModalOpen(false);
    } else {
      alert("Por favor, preencha um número de episódio válido e um link");
    }
  };

  const handleProcessMultiEpisodesText = () => {
    const { multiEpisodesText, startNumber } = formState;

    if (multiEpisodesText.trim() === "") {
      alert("Por favor, insira algum texto para processar.");
      return;
    }

    const links = multiEpisodesText.split(/\n|\r/);
    let currentNumber = parseInt(startNumber);

    const newEpisodes = links.reduce((result, link) => {
      let newEpisode = {
        episode_id: null,
        episode_number: currentNumber,
        season_number: formState.seasonNumber * 1,
        episode_link: link.trim(),
      };

      if (link.trim() !== "") {
        result[currentNumber] = newEpisode;
        currentNumber++;
      }
      return result;
    }, {});

    setFormState((prevData) => ({
      ...prevData,
      multiEpisodesText: "",
    }));

    setFormData((prevData) => ({
      ...prevData,
      episodes: {
        ...prevData.episodes,
        [formState.seasonNumber]: {
          ...(prevData.episodes[formState.seasonNumber] || {}),
          ...newEpisodes,
        },
      },
    }));

    setModalOpen(false);
  };

  useEffect(() => {
    if (defaultValues) {
      setFormData((prevData) => ({
        ...prevData,
        imageUrl: defaultValues.image_url,
        releaseDate: defaultValues.release_date,
        isMovie: defaultValues.is_movie,
      }));
    }
  }, [defaultValues]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid component="form" onSubmit={handleSubmit} container spacing={2}>
        <Grid item xs={6} md={8}>
          <Box sx={boxSstyle}>
            <h2 style={{ textAlign: "left" }}>
              {defaultValues ? "Editar" : "Adicionar Nov" + (formData.isMovie ? "o " : "a ")} {formData.isMovie ? "filme" : "serie"}
            </h2>
            <TextField label="Título" type="text" name="title" value={formData.title} onChange={handleChange} sx={{ marginBottom: "20px" }} />
            <TextField label="TMDB" type="text" name="tmdb" value={formData.tmdb} onChange={handleChange} sx={{ marginBottom: "20px" }} />
            <TextField label="Descrição" multiline minRows={3} id="description" name="description" value={formData.description} onChange={handleChange} sx={{ marginBottom: "20px", width: "100%" }} />
            <TextField label="URL da Imagem" type="text" name="imageUrl" value={formData.imageUrl} onChange={handleChange} sx={{ marginBottom: "20px" }} />
            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  maxHeight: 500,
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  overflowY: "auto",
                }}
              >
                {formData.isMovie ? (
                  <Typography sx={{ mt: 2 }}>Adicionando link...</Typography>
                ) : formState.editingEpisode === true ? (
                  <>
                    Editar item
                    <br></br>
                    <small> (Necessita salvar o post para guardar alterações)</small>
                  </>
                ) : (
                  <FormGroup sx={{ marginTop: "10px" }}>
                    <FormControlLabel
                      control={<Switch checked={formState.seriesType === "multiEpisodes"} onChange={() => handleChange({ target: { name: "seriesType", value: "multiEpisodes" } })} />}
                      label="Separar links"
                    />
                    <FormControlLabel
                      control={<Switch checked={formState.seriesType === "singleEpisode"} onChange={() => handleChange({ target: { name: "seriesType", value: "singleEpisode" } })} />}
                      label="Episódio Único"
                    />
                  </FormGroup>
                )}
                {formState.seriesType === "singleEpisode" || formData.isMovie === 1 ? (
                  <Box sx={{ display: "flex", flexDirection: "column", marginTop: "10px" }}>
                    <TextField label="Número do item" type="number" name="episodeNumber" value={formState.episodeNumber} onChange={handleChange} sx={{ marginBottom: "10px" }} />
                    {!formData.isMovie && (<TextField label="Número da temporada" type="number" name="seasonNumber" value={formState.seasonNumber} onChange={handleChange} sx={{ marginBottom: "10px" }} />)}
                    <TextField label="Link do item" type="text" name="episodeLink" value={formState.episodeLink} onChange={handleChange} sx={{ marginBottom: "10px" }} />
                    <Button variant="contained" color="primary" onClick={handleAddEpisode} sx={{ marginBottom: "10px" }}>
                      {formState.editingEpisode !== true ? (formData.isMovie ? 'Adicionar link' : 'Adicionar Episódio') : (formData.isMovie ? 'Editar link' : 'Editar Episódio')}
                    </Button>
                  </Box>
                ) : (
                  <Box sx={{ display: "flex", flexDirection: "column", marginTop: "10px" }}>
                    <TextField label="Multi-Links" multiline minRows={3} id="multiEpisodesText" name="multiEpisodesText" value={formState.multiEpisodesText} onChange={handleChange} sx={{ marginBottom: "10px" }} />
                    <TextField label="Número Inicial" type="number" name="startNumber" value={formState.startNumber} onChange={handleChange} sx={{ marginBottom: "10px" }} />
                    <TextField label="Número da temporada" type="number" name="seasonNumber" value={formState.seasonNumber} onChange={handleChange} sx={{ marginBottom: "10px" }} />

                    <Button variant="contained" color="primary" onClick={handleProcessMultiEpisodesText} sx={{ marginBottom: "10px" }}>
                      Processar itens
                    </Button>
                  </Box>
                )}
                <Button onClick={() => setModalOpen(false)}>Fechar Modal</Button>
              </Box>
            </Modal>

            {successMessage && (
              <Alert severity="success" sx={{ marginBottom: "10px" }}>
                {successMessage}
              </Alert>
            )}
            {errorMessage && (
              <Alert severity="error" sx={{ marginBottom: "10px" }}>
                {errorMessage}
              </Alert>
            )}
          </Box>
          <br />
          <Box sx={boxSstyle}>
            {/* Exibindo episódios adicionados */}
            {formData.isMovie ?
              Object.entries(formData.episodes).map(([seasonNumber, episodes]) => (
                <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                  {Object.entries(episodes).map(([episodeNumber, link]) => (
                    <Box key={episodeNumber} sx={{ display: "flex", alignItems: "center" }}>
                      <Link to={`/video/${formData.tmdb}/${seasonNumber}/${episodeNumber}`} style={{ textDecoration: "none", width: "-webkit-fill-available", maxWidth: "71%" }}>
                        <Chip sx={{ marginBottom: "5px", width: '100%' }} label={`Servidor ${episodeNumber}: ${link.episode_link}`} />
                      </Link>
                      <Button variant="outlined" color="primary" onClick={() => { setFormState({ ...formState, editingEpisode: true }); handleEditEpisode({ seasonNumber, episodeNumber }); }} sx={{ marginLeft: "10px" }}>

                        <EditIcon />
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleRemoveEpisode(seasonNumber, episodeNumber)}
                        sx={{ marginLeft: "5px" }}
                      >
                        <ClearIcon />
                      </Button>

                    </Box>
                  ))}
                </Box>
              )) :
              Object.entries(formData.episodes).map(([seasonNumber, episodes]) => (
                <Accordion key={seasonNumber}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`season-${seasonNumber}-content`}
                    id={`season-${seasonNumber}-header`}
                  >
                    <Typography>{`Temporada ${seasonNumber}`}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                      {Object.entries(episodes).map(([episodeNumber, link]) => (
                        <Box key={episodeNumber} sx={{ display: "flex", alignItems: "center" }}>
                          <Link to={`/video/${formData.tmdb}/${seasonNumber}/${episodeNumber}`} style={{ textDecoration: "none", width: "-webkit-fill-available", maxWidth: "71%" }}>
                            <Chip sx={{ marginBottom: "5px", width: '100%' }} label={`Episódio ${link.season_number}x${episodeNumber}: ${link.episode_link}`} />
                          </Link>
                          <Button variant="outlined" color="primary" onClick={() => { setFormState({ ...formState, editingEpisode: true }); handleEditEpisode({ seasonNumber, episodeNumber }); }} sx={{ marginLeft: "10px" }}>

                            <EditIcon />
                          </Button>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => handleRemoveEpisode(seasonNumber, episodeNumber)}
                            sx={{ marginLeft: "5px" }}
                          >
                            <ClearIcon />
                          </Button>

                        </Box>
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))}

            <Button
              sx={{ marginTop: "5px" }}
              variant="contained"
              color="secondary"
              onClick={() => {
                if (formData.isMovie) {
                  setFormState({
                    ...formState, multiEpisodesText: "",
                    seriesType: "singleEpisode", //multiEpisodes
                    multidata: "",
                    startNumber: null,
                    seasonNumber: 0,
                    episodeLink: ""
                  });
                } else {
                  setFormState({
                    ...formState,
                    seriesType: "singleEpisode",
                    multidata: "",
                    multiEpisodesText: "",
                    startNumber: null,
                    episodeNumber: "",
                    seasonNumber: "",
                    episodeLink: "",
                    editingEpisode: false,
                  });
                }

                setModalOpen(true);
              }}
            >
              {formData.isMovie ? "Adicionar link" : "Adicionar episodios"}
            </Button>
          </Box>
        </Grid>

        <Grid item xs={6} md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              margin: "auto",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#fff",
            }}
          >
            <Box>
              {
                <img
                  src={formData.imageUrl ? formData.imageUrl : "/default.png"}
                  style={{
                    background: "#ccc",
                    maxWidth: "200px",
                    height: "300px",
                    minWidth: "200px",
                    minHeight: "300px",
                    marginBottom: "20px",
                  }}
                />
              }

              <Typography component="div" sx={{ marginBottom: "5px" }}>
                <Box component="span" sx={{ marginRight: "10px" }}>
                  Série
                </Box>
                <Switch checked={formData.isMovie} onChange={(e) => {
                  handleChange(e);
                }} name="isMovie" inputProps={{ "aria-label": "controlled" }} />

                <Box component="span" sx={{ marginLeft: "10px" }}>
                  Filme
                </Box>
              </Typography>

              <DatePicker label="Data de Lançamento" inputFormat="DD/MM/YYYY" value={dayjs(formData.releaseDate)} onChange={(date) => handleDateChange(date)} renderInput={(params) => <TextField {...params} />} />

              <Button type="submit" variant="contained" color="primary" sx={{ width: "100%", marginTop: "20px" }}>
                {defaultValues ? "Salvar alterações" : "Adicionar post"}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default AddPost;
