import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AddPost from './AddPost'; // Importa o componente AddPost
const BASE_URL = window.BASE_URL;

const EditPost = () => {
  const { id } = useParams();
  const [postToEdit, setPostToEdit] = useState(null);

  // Simula o carregamento dos dados do post a ser editado
  useEffect(() => {
    // Suponha que fetchData seja uma função que obtém os dados do post do backend
    const fetchData = async () => {
      try {
        // Substitua esta linha pela lógica real de busca de dados do post pelo ID
        const response = await fetch(`${BASE_URL}posts/get-post.php?id=${id}`);
        const data = await response.json();
        setPostToEdit(data); // Define os dados do post a ser editado no estado local
      } catch (error) {
        console.error('Erro ao buscar dados do post:', error);
      }
    };

    fetchData();
  }, [id]);

  // Função para lidar com a edição do post
  const handleEditPost = async (editedPostData) => {
    try {
      // Substitua a linha abaixo pela lógica real para enviar os dados editados para o backend
      const response = await fetch(`${BASE_URL}posts/edit-post.php`, {
        method: 'PATCH', // Use PATCH para atualizações parciais
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(editedPostData),
      });

      const result = await response.json();

      if (result.success) {
        console.log('Post editado com sucesso:', editedPostData);
      } else {
        console.error('Erro ao editar post:', result.message);
      }
    } catch (error) {
      console.error('Erro ao editar post:', error);
    }
  };

  return (
    <div>
      {postToEdit && (
        <AddPost
          onSavePost={handleEditPost}
          defaultValues={postToEdit} // Passa os valores do post a ser editado como defaultValues
        />
      )}
    </div>
  );
};

export default EditPost;
