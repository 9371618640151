import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './components/Header';
import AppRoutes from './routes';
import { useNavigate, useLocation } from 'react-router-dom';

const theme = createTheme();

function App() {
  const initialLoginState = localStorage.getItem('authToken') !== null;
  const [isLoggedIn, setLoggedIn] = useState(initialLoginState);
  const [isVideoRoute, setVideoRoute] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = (token) => {
    setLoggedIn(true);
    localStorage.setItem('authToken', token);
  };

  const handleLogout = () => {
    setLoggedIn(false);
    localStorage.removeItem('authToken');
  };

  useEffect(() => {
    const updateVideoRoute = () => {
      const isVideo = location.pathname.startsWith('/video');
      setVideoRoute(isVideo);
    };

    // Atualize na montagem inicial
    updateVideoRoute();

    // Use um ouvinte de navegação para atualizações em tempo real
    return navigate(updateVideoRoute);
  }, [navigate]);

  console.log('isVideoRoute:', isVideoRoute);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {isVideoRoute === true ? (
        <AppRoutes isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />
      ) : (
        <Box sx={{ display: 'flex' }}>
          {isLoggedIn && <Header isLoggedIn={isLoggedIn} onLogout={handleLogout} />}
          <Container component="main" maxWidth="md" sx={{ textAlign: 'center', marginTop: '50px' }}>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              {/* Passando a função de login e logout para as rotas */}
              <AppRoutes isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />
            </Box>
          </Container>
        </Box>
      )}
    </ThemeProvider>
  );
}

export default App;
